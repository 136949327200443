.sidebar-badge {
  position: absolute;
  right: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 18px;
  @include themify($themes) {
    background-color: themed('menuBadgeBgColor');
    color: themed('menuBadgeTextColor');
  }
  font-size: 11px;
  height: 25px;
  min-width: 25px;
  padding: 2px 8px;
  transition: background-color 0.05s ease-in;
  @include respond(tab-land){
    right: -8px;
    top: 0;
    background-color: rgba($color-background-dark, 0.6);
    color: $color-background-light;
  }
  @include respond(phone){
    right: 50px;
    top: 17px;
  }
  .pro {
    color: rgba($color-text-dark, 0.7) !important;
  }
}
