.tag {
  font-size: 12px;
  font-weight: 600;
  border-radius: 17px;
  padding: .3rem 1.2rem;
  white-space: nowrap;

  &__default{
    @include themify($themes) {
      border: 1px solid themed('secondaryBtnBorderColor');
      color: themed('secondaryBtnTextColor');
    }
    cursor: default;
    transition: all 0.1s ease-in-out;
    &:hover {
      @include themify($themes) {
        background-color: themed('secondaryBtnBgColorHover');
      }
    }
    &:focus {
      outline: none;
    }
  }
  &__success {
    background-color: $color-success-light;
    color: $color-success-dark;
  }
  &__danger {
    background-color: $color-danger-light;
    color: $color-danger-dark;
  }
  &__warning {
    background-color: $color-warning-light;
    color: $color-danger-dark;
  }
  &__neutral {
    background-color: $color-background-input;
  }
  &__neutral-border {
    background-color: $color-background-input;
    border: 1px solid $color-background-dark;
  }
}