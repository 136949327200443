.main-content {
  position: relative;

  &__box {
    // min-height: 70vh;
    width: 100%;
    position: relative;
    background-color: $color-white;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    margin-bottom: 3rem;
    @include respond(tab-land) {
      margin-bottom: 2rem;
    }
    .heading-secondary {
      @include respond(phone) {
        margin-bottom: 0.5rem;
        line-height: 30px;
      }
    }

    .pr-0 {
      @include respond(phone) {
        padding-right: 15px !important;
      }
    }

    &--inner-wrapper,
    &--header,
    &--footer {
      padding: 1.2rem;
    }
    &--inner-wrapper {
      width: 100%;
      .heading-secondary {
        @include respond(phone) {
          margin-bottom: 0.5rem;
          line-height: 30px;
        }
      }
      .form__box {
        //height: 9rem;

        // @include respond(phone) {
        //   height: 9rem;
        // }
      }
      .react-datepicker-wrapper {
        width: 100%;
      }
    }
    &--header {
      border-radius: 4px 4px 0px 0px;
      background-color: $color-content-light;
    }
    &--body {
      //padding: 0 1.2rem;
      h2 {
        margin: 0;
      }
      .btn__link.danger {
        margin: 0;
      }
      span {
        margin-left: 0.5rem;
      }
      .form__span {
        left: 68%;
      }
      .text-area {
        background-color: #f9fafb;
        color: #111827;
        font-size: 14px;
        padding: 1.2rem;
        border-radius: 4px;
        -webkit-border-radius: 4px;
        border: 1px solid #d1d5db;
        width: 100%;
        &:focus {
          outline: none;
          padding: 1.1rem;
          border: 2px solid #6b7280;
          border-radius: 4px;
        }
      }
      .form__box {
        display: flex;
        height: 103px;

        @include respond(big-phone) {
          height: auto;
        }
      }
    }
    &--footer {
      background-color: $color-white;
      border-radius: 0px 0px 4px 4px;
    }

    .nova-stavka{
      &__hover:hover{
        @include themify($themes) {
          background-color: themed('tableRowBgActiveColor');
          cursor: pointer;
        }
      }
    }

    .hr-main {
      border: 0;
      height: 1px;
      background-color: #d1d5db;
      margin-bottom: 0;
    }
    .content {
      position: relative;
      &__btn {
        position: absolute;
        right: 1rem;
        top: 2rem;
        cursor: pointer;
        z-index: 100;
        &:focus {
          outline: none;
        }
      }
      &__logo {
        display: flex;
        width: 50%;
        img {
          position: relative;
          display: block;
          max-height: 3rem;
          margin-bottom: 1rem;
        }
      }
      .img {
        background-position: center;
        background-size: cover;
        width: 100%;
        margin: 2rem 0;
        border-radius: 4px;
      }
      &__color {
        height: 40px;
        width: 40px;
        border-radius: 100%;
        position: relative;
        margin-right: 3rem;
        margin-bottom: 3rem;
        cursor: pointer;

        &--light-blue {
          background-color: #0ea5e9;
        }
        &--blue {
          background-color: #2563eb;
        }
        &--indigo {
          background-color: #4f46e5;
        }
        &--violet {
          background-color: #7c3aed;
        }
        &--purple {
          background-color: #9333ea;
        }
        &--fuchsia {
          background-color: #c026d3;
        }
        &--pink {
          background-color: #db2777;
        }
        &--rose {
          background-color: #e11d48;
        }
        &--cyan {
          background-color: #0891b2;
        }
        &--teal {
          background-color: #0d9488;
        }
        &--emerald {
          background-color: #059669;
        }
        &--green {
          background-color: #16a34a;
        }
        &--lime {
          background-color: #65a30d;
        }
        &--yellow {
          background-color: #ca8a04;
        }
        &--amber {
          background-color: #d97706;
        }
        &--orange {
          background-color: #ea580c;
        }
        &--orange {
          background-color: #ea580c;
        }
        &--cool-gray {
          background-color: #6b7280;
        }
        &--red {
          background-color: #dc2626;
        }
      }
      &__grid {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-content: center;
        align-items: flex-start;
        .active-color {
          position: relative;
          width: 40px;
          height: 40px;
          &:before {
            content: '';
            position: absolute;
            background-image: url('../../assets/icon/ch-mark-white.svg');
            background-repeat: no-repeat;
            background-size: contain;
            width: 18px;
            height: 14px;
            top: 12px;
            left: 12px;
          }
        }
      }
      &__box-wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 100%;
      }
      &__box {
        color: $color-link-dark;
        position: relative;
        width: 22.5%;
        //height: 18rem;
        padding: 2rem 0.3rem;
        background: $color-white;
        border-radius: 4px;
        border: 1px solid $border-input-lines;
        cursor: pointer;
        @include respond(tab-port) {
          width: 48%;
          margin-bottom: 2rem;
        }
        svg {
          stroke: $color-link-dark;
        }

        img {
          width: 100%;
        }
        &.active-invoice-layout {
          border: 1px solid $color-link-dark;
          color: $color-link-dark;
          &::after {
            content: '';
            position: absolute;
            right: 1rem;
            bottom: 0.1rem;
            width: 14px;
            height: 18px;
            background-image: url('../../assets/icon/checkmark.svg');
            background-repeat: no-repeat;
          }
        }
      }
      .msg-center{
        display: flex;
        justify-content: center;
        margin: 10rem 0;
      }
    }

    // .search__icon {
    //   width: 20px;
    //   height: 30px;
    //   fill: #000000;
    // }
  }
  &__search-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 1.5rem;
    .react-datepicker-wrapper {
      width: 60%;
      @include respond(tab-port) {
        margin-top: 1.5rem;
      }
      @include respond(big-phone) {
        margin-top: 1rem;
      }
      @include respond(phone) {
        width: 100%;
        margin-top: 0;
      }
    }
    .react-datepicker-wrapper:first-of-type {
      margin-right: 1.5rem;
      @include respond(big-phone) {
        margin-right: 1rem;
      }
    }
    .search {
      width: 100%;
      display: flex;
      align-items: center;
      &__input {
        background-color: $color-background-input;
        width: 100%;
        padding: 1rem 4rem;
        height: 45px;
      }
    }
    .select,
    .date-select {
      background-color: $color-background-input;
      color: $color-text-dark;
      font-size: $font-size-default;
      padding: 1rem;
      border-radius: 4px;
      -webkit-border-radius: 4px;
      border: 1px solid $border-input-lines;
      width: 100%;
      box-sizing: border-box;
      height: 45px;

      &:focus {
        outline: none;
      }
    }
    .select {
      margin-left: 1.5rem;
      width: 25%;
      @include respond(phone) {
        margin-left: 0;
        width: 100%;
      }
    }
    .box-wrapper,
    ul {
      justify-content: start;
      flex-wrap: nowrap;
      flex-grow: 1;
      list-style: none;
      @include respond(big-phone){
        flex-wrap: wrap;
        justify-content: space-between;
      }
      .box {
        display: block;
        flex-grow: 1;
        height: auto;

        @include respond(big-phone){
          width: 49%;
          margin-bottom: 1rem;
          flex-grow: 0;

        }
      }

      li {
        margin-right: 1rem !important;
        @include respond(big-phone){
          margin-right: 0 !important;

        }
      }
      li:last-child {
        margin-right: 0!important;
      }
      li:first-child {
        max-width: 50%;
      }
    }
  }

}
.right-wrapper {
  // position: absolute;
  @include respond(tab-land){
    left: 0rem;
    padding-right: 0;
    transition: left 0.2s linear;
  }


  @include respond(big-desktop) {
    padding-right: 15px;
  }
  @include respond(big-phone) {
    padding-right: 15px;
  }

}
.push {
  @include respond(tab-land){
    position: relative;
    left: 30rem;
  }
  @include respond(big-phone){
    position: relative;
    left: 0;
  }
}
