.title {
  display: flex;
  //justify-content: space-between;
  align-items: center;

  // @include respond(phone){
  //   align-items: start;
  // }

  .btn-dd {
    position: relative;
    
    &:hover {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      .drop-down {
        top: 45px;
        right: 0;
        display: block;
        svg {
          stroke: $color-text-light;
        }
        @include respond(big-phone) {
          width: 23rem;
        }
        @include respond(phone) {
          width: 100%;
        }
      }
    }
  }
}
.main-link {
  margin-top: 3rem;

  svg {
    margin-right: 2rem;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out; /** Chrome & Safari **/
    -moz-transition: all 0.3s ease-in-out; /** Firefox **/
    -o-transition: all 0.3s ease-in-out; /** Opera **/
  }
  &:hover {
    svg {
      transform: translate(-0.5rem, 0);
      -webkit-transform: translate(-0.5rem, 0); /** Chrome & Safari **/
      -o-transform: translate(-0.5rem, 0); /** Opera **/
      -moz-transform: translate(-0.5rem, 0); /** Firefox **/
    }
  }
}
