.table-wrapper {
  display: flex;
  // overflow-x: scroll; // pravi probleme kod prikaza floating menija na tri tacke kod liste racuna
}
.table {
  width: 100%;
  border-collapse: collapse;

  .img-round {
    @include respond(big-phone) {
      display: none;
    }
  }
}
table.cursor {
  tbody tr {
    cursor: pointer;
    &:hover{
      @include themify($themes) {
        background-color: themed('tableRowBgActiveColor');
      }
    }
  }
}
tbody {
  tr {
    // cursor: pointer;

    @include respond(big-phone){
      border-bottom: 1px solid $color-content-lines;
    }
  }
  .active {
    @include themify($themes) {
      background: themed('tableRowBgActiveColor');
    }
   // background: $color-content-light;
  }
}
th {
  background-color: $color-content-light;
  color: white;
  font-weight: 500;
  span {
    display: flex;
    flex-direction: column;
    .desc {
      font-size: 10px;
    }
    .asc {
      font-size: 10px;
      transform: rotate(180deg);
    }
  }
  button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    background-color: transparent;
    border: none;
    //color: white;
    outline: none;
    cursor: pointer;
  }
  input {
    border-radius: 5px;
    border: none;
    padding: 5px 10px;
    outline: none;
  }
}
td {
  padding: 2rem 1.5rem;

  @include respond(tab-port) {
    padding: 1.5rem 1rem;
  }
  @include respond(big-phone) {
    display: block;
    padding: .5rem 1.5rem;
  }
}
th {
  padding: 1.5rem;
  @include respond(big-phone) {
    display: none;
  }
}
th:first-child {

  @include respond(big-phone) {
    display: block;
    width: 100%;
  }
}
th:last-child {
  width: 20%;
  text-align: right;

  @include respond(tab-land) {
    width: 15%;
  }
}
td,
th {
  border-bottom: 1px solid $color-content-lines;
  text-align: left;
  @include respond(big-phone){
    border-bottom: none;
  }
}
td:last-child {
  text-align: right;
  padding-bottom: 2rem;
}
td:first-child {
  @include respond(big-phone){
    padding-top: 2rem;
  }
}
.inner-td-wrapper {
  display: flex;
  align-items: center;
  @include respond(big-phone){
    width: 85%;
  }
}
.td-title {
  //margin-left: 2rem;
  p {
    position: relative;
    display: block;
    i{
      position: absolute;
      margin-top: .25rem;
      margin-left: .25rem;
      @include respond(tab-land){
        margin-top: .5rem;
      }
    }
  }

  @include respond(big-phone) {
    margin-left: unset;
  }
}
