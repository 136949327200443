.filepond--root .filepond--credits[style]{
    display: none;
}

.filepond--panel-root{
    background-color: $color-background-input;
    border-radius: 4px;
    border: 1px solid $border-input-lines;
}

.filepond--root :not(text){
    font-size: 1.4rem;
}

.filepond--root.filepond--hopper{
    z-index: 0;
}

// .filepond--drop-label.filepond--drop-label label{
//     padding: 2rem;
// }

// .filepond--drop-label {
//     z-index: -1;
// }