.header {
  display: flex;
  height: 7rem;
  border-bottom: 1px solid #d1d5db;
  input {
    @include respond(phone) {
      display: none;
    }
  }
  .search {
    width: 100%;
    height: 100%;

    @include respond(tab-port) {
      width: 60%;
    }

    //   border: 4px solid white;
  }
  .user-nav {
    width: 40%;
    height: 100%;
    //   border: 4px solid lightgreen;
  }
}
