.drop-down {
  display: flex;
  position: absolute;
  display: none;
  @include themify($themes) {
    background-color: themed('dropdownBgColor');
  }
  //background-color: $color-hover;
  border-top-right-radius: 0px;
  border-top-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  white-space: nowrap;
  box-shadow: 0px 4px 6px rgba($color-black, 0.2);
  z-index: 4;
  opacity: 1;
  transition: all 0.1s ease-in;

  a {
    display: flex;
    align-items: center;
    padding: 1rem 2rem;
    @include themify($themes) {
      color: themed('dropdownTextColor');
    }
    //color: $color-text-dark;
    font-size: $font-size-default;
    font-weight: 400;

    svg {
      margin-right: 2rem;
      @include themify($themes) {
        stroke: themed('iconColorLight');
      }
      path {
        @include themify($themes) {
          stroke: themed('iconColorLight');
        }
      }
    }
    &:hover {
      @include themify($themes) {
        background-color: themed('dropdownBgColorHover');
      }
      color: $color-content-light;
      svg {
        @include themify($themes) {
          stroke: themed('iconColorLightHover');
        }
        path {
          @include themify($themes) {
            stroke: themed('iconColorLightHover');
          }
        }
      }
    }
    &:first-child {
      border-top-left-radius: 4px;
      border-top-right-radius: 0px;
    }
    &:last-child {
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }
    &.icon {
      width: 20px;
      height: 20px;
    }
  }
}

#p-filter {
  position: relative;
  &:hover {
    .drop-down {
      top: 3.5rem;
      right: 0;
      display: block;

      @include respond(big-desktop) {
        top: 3.3rem;
      }
      svg {
        stroke: $color-text-light;
      }
    }
  }
}
