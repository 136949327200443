.icon {
  display: flex;

  &.s {
    width: 14px;
    height: 14px;
  }
  &.sm {
    width: 18px;
    height: 18px;
  }
  &.md {
    width: 20px;
    height: 20px;
  }
  &.lg {
    width: 24px;
    height: 24px;
  }
  &.xl {
    width: 40px;
    height: 40px;
  }
  &__primary {
    stroke: $color-primary
  }
  &__light {
    stroke: $color-content-light;
  }
  &__light-fill {
    fill: $color-content-light;
  }
  &__dark {
    stroke: $color-text-dark !important;
  }
  &__fill-color-badge {
    fill: $color-check-badge;
  }
  &__stroke-link {
    @include themify($themes) {
      stroke: themed('linkIconColor');
    }
    // stroke: $color-link-dark;
  }
  &__fill-link {
    @include themify($themes) {
      fill: themed('linkIconColor');
    }
    // stroke: $color-link-dark;
  }
  &__drop-down {
    stroke: $color-sidebar-icon;
  }
  &__success {
    stroke: $color-success-dark;
  }
}
