.box-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  margin-top: 1rem;
}
.box,
.box-dashboard,
.box-modules,
.box-news {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: $color-white;
  box-sizing: border-box;
  border-radius: 4px;
  overflow: hidden;
}
.box {
  justify-content: center;
  border: 1px solid $border-input-lines;
  width: 24%;
  height: 7rem;
  padding: 0.8rem;
  @include respond(phone) {
    width: 49%;
    margin-bottom: 1rem;
  }

  h2 {
    margin-bottom: unset;
  }
  &__close {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    cursor: pointer;
    svg {
      stroke: $color-text-light;
    }
  }
}
.box-dashboard-wrapper {
  position: relative;
  h2 {
    margin-bottom: 2rem;
    @include respond(phone) {
      margin-bottom: 1rem;
    }
  }
}
.box-dashboard {
  width: 100%;
  height: 9rem;
  padding: 1.5rem 2rem;
  margin-bottom: 4rem;
  box-shadow: 0px 4px 6px rgba($color-black, 0.2);
  justify-content: space-between;

  @include respond(tab-port) {
    padding: 1rem;
  }

  @include respond(phone) {
    margin-bottom: 2rem;
  }

  h2 {
    margin-bottom: unset;
    @include respond(tab-land) {
      font-size: 21px;
    }
    @include respond(tab-port) {
      font-size: 18px;
    }

    svg {
      height: 21px;
      width: 18px;
      margin-right: 1rem;
      @include respond(tab-port) {
        height: 16px;
        width: 14px;
        margin-right: 0.2rem;
      }
    }
    &.success {
      color: $color-success-600;
    }
    &.danger {
      color: $color-danger-600;
    }
  }

  &__top,
  &__btm {
    display: flex;
    height: 50%;
  }
  &__top {
    span {
      @include respond(tab-land) {
        font-size: 14px;
      }
      @include respond(tab-port) {
        font-size: 13px;
        width: 90%;
        line-height: 2rem;
      }
    }
  }
  &__btm {
    justify-content: space-between;
    align-items: center;
    span {
      @include respond(tab-port) {
        font-size: 14px;
      }
    }
  }
  &__icon {
    position: absolute;
    top: 1.5rem;
    right: 2rem;
    @include respond(tab-port) {
      top: 1rem;
      right: 1rem;
    }
    svg {
      width: 22px;
      height: 22px;
    }
  }
}
.box-modules-wrapper {
  position: relative;
  display: block;
  margin-bottom: 2rem;
  .title {
    align-items: flex-end !important;
    margin-bottom: 1rem;
    h2 {
      margin-bottom: 0;
      @include respond(tab-port) {
        width: 75%;
      }
      p {
        font-weight: 400;
      }
    }
  }
}
.box-modules,
.box-news {
  position: relative;
  padding: 1.5rem 2rem;
  box-shadow: 0px 4px 6px rgba($color-black, 0.2);
  margin-bottom: 2rem;

  h3 {
    span {
      margin-left: 1rem;
      font-size: 12px;
      font-weight: 600;
    }
  }
  .mark {
    position: absolute;
    top: 1.5rem;
    right: 2rem;
    border: 1px solid $color-primary;
    height: 18px;
    width: 18px;
    border-radius: 50%;
  }

  &.active {
    border: 1px solid $color-link-dark;
    box-shadow: 0px 4px 6px rgba($color-link-dark, 0.2);
    .mark {
      background-color: $color-link-dark;
      border: 1px solid $color-link-dark;
      &:before {
        content: "";
        //background-image: url("../assets/icon/ch-mark-white.svg");
        background-repeat: no-repeat;
        position: absolute;
        top: 1.5px;
        left: 3px;
        width: 15px;
        height: 15px;
        display: block;
        transform: rotate(-17deg);
      }
    }
  }
  hr {
    margin: 2rem 0;
  }
  &__items {
    list-style: none;
  }
  &__item {
    display: flex;
    line-height: 18px;
    span {
      display: block;
      height: 100%;
      margin-right: 1rem;
      svg > path {
        stroke: $color-success-600;
      }
    }
    .active {
      color: $color-link-light !important;
    }
  }
}
.box-modules {
  cursor: pointer;
}
.box-news {
  margin-bottom: 3rem;
  @include respond(tab-land){
    margin-bottom: 2rem;
  }
  h3 {
    cursor: pointer;
    &:hover {
      color: $color-link-light;
    }
  }
  img {
    margin: 2rem 0;
  }
  &__content {
    p:not(:last-child) {
      margin-bottom: 1rem;
    }
    span{
      display: flex;
      flex-direction: column;
    }
    a {
      cursor: pointer;
    }
  }
  &__items{
    display: flex;
    flex-wrap: wrap;
  }
  &__item {
    line-height: 30px;
    margin-right: 3rem;
    @include respond(tab-port){
      margin-right: 1rem;
    }
  }
}
