.floating-btn {
    display: none;
    position: fixed;
    bottom: 3rem;
    left:calc(50% - 8rem);
    @include themify($themes) {
        background-color: themed(floatingBtnBgColor);
        color: themed(floatingBtnColor);
      }
    width: 16rem;
    padding: 1.2rem 2rem;
    border-radius: 4px;
    text-align: center;
    box-shadow: 0px 15px 25px rgba(0, 0, 0, 0.3);
    cursor: pointer;
    transition: all .15s ease-in-out;
    &:hover{
        @include themify($themes) {
            background-color: themed(floatingBtnHoverBgColor);
            color: themed(floatingBtnHoverColor);
          }
    }

    @include respond(tab-land){
        display: block;
    }
   }
   