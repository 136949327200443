.section-box {
  display: flex;
  flex-wrap: wrap;
  //border: 1px solid black;
  width: 100%;
  justify-content: space-between;
  //height: 10rem;
  @include respond(tab-port) {
    flex-direction: column;
  }
  &__half {
    flex: 0 0 49%;
  }
  &__left {
    flex: 0 0 33%;
    height: 100%;
    //border: 1px solid red;
    @include respond(tab-port) {
      margin-bottom: 1.5rem;
    }
    // @include respond(phone) {
    //   margin-bottom: 0;
    // }
    &--top {
      height: 20%;
    }
    &--bottom {
      height: 80%;
    }
  }
  &__right {
    flex: 0 0 65.7%;
    &--top-wrap {
      display: flex;
      flex-wrap: wrap;
      height: 50%;
      justify-content: space-between;
      .w-32 {
        width: 32%;
        @include respond(phone) {
          width: 100%;
        }
      }
      .el {
        width: 23.5%;
        margin-bottom: 1.5rem;

        .form__group {
          margin-bottom: 1.5rem !important;
        }
        .form__group:not(:last-child) {
          margin-bottom: 1.5rem !important;
        }

        @include respond(tab-port) {
          width: 24%;
        }
        @include respond(big-phone) {
          display: flex;
          justify-content: space-between;
          width: 100% !important;
          margin-bottom: 0;
          .form__group {
            margin-bottom: 0 !important;
            width: 48.5%;
          }
        }
      }
      .half {
        width: 49%;
        @include respond(big-phone) {
          width: 48.75%;
        }
        @include respond(phone) {
          width: 48.5%;
        }
      }
    }
    &--bottom-wrap,
    &--full-h {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .price {
        width: 32%;
        @include respond(tab-port) {
          width: 32.4%;
        }
        @include respond(big-phone) {
          width: 100%;
          margin-bottom: 1.5rem;
        }
      }
      .half {
        width: 49%;
        @include respond(big-phone) {
          width: 48.75%;
        }
        @include respond(phone) {
          width: 48.5%;
        }
      }
    }
    &--bottop-wrap {
      height: 50%;
    }
    &--full-h {
      height: 100%;
      padding-top: 24px;

      @include respond(tab-port) {
        height: 103px;
        padding-top: 0;
      }
      @include respond(big-phone) {
        height: auto;
      }
    }
  }
}
