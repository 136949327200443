.form {
  position: relative;

  .form-control {
    margin-bottom: 1.5rem;
  }

  hr {
    margin: 2.5rem 0 !important;
  }

  &__group {
    position: relative;
    @include respond(phone) {
      margin-bottom: 2rem !important;
    }
  }
  &__group:not(:last-child) {
    margin-bottom: 2rem;
  }

  &__box {
    position: relative;
    background-color: $color-white;
    color: $color-text-dark;
    font-size: $font-size-default;
    padding: 1rem;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    border: 1px solid $border-input-lines;
    width: 100%;
    .heading-secondary {
      position: absolute;
      bottom: 0;
      margin-bottom: 0;
      del{
        display: block;
        line-height: 1;
        font-weight: 400;
        font-size: $font-size-default;
      }
      @include respond(big-phone) {
        right: 1rem;
        del{
          display: inline-block;
          margin-right: 10px;
        }
      }
      @include respond(phone) {
        font-size: 18px;
        del{
          display: inline-block;
          margin-right: 10px;
        }
      }
    }

    @include respond(phone) {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    h2 {
      position: absolute;
      bottom: 1rem;
      margin-bottom: unset;
    }
  }

  &__area {
    width: 100%;
    position: relative;

    input[type='file'] {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      opacity: 0;
      cursor: pointer;
      &:focus {
        outline: none;
      }
    }
    .file-dummy {
      width: 100%;
      padding: 3rem;
      height: 10rem;
      border-radius: 4px;
      background-color: $color-content-light;
      border: 2px dashed $border-input-lines;
      text-align: center;
      transition: background 0.3s ease-in-out;

      @include respond(tab-land) {
        padding: 2rem;
      }
      .success {
        display: none;
      }
    }

    &:hover .file-dummy {
      background-color: $color-content-light;
    }

    input[type='file']:focus + .file-dummy {
      outline: 2px solid rgba(255, 255, 255, 0.5);
      outline: -webkit-focus-ring-color auto 5px;
    }

    input[type='file']:valid + .file-dummy {
      border-color: rgba(0, 255, 0, 0.4);
      background-color: rgba(0, 255, 0, 0.3);

      .success {
        display: inline-block;
      }
      .default {
        display: none;
      }
    }
  }

  // input[type="checkbox"]{
  //   border: 1px solid violet;
  // }
  &__input,
  &__select,
  &__date-picker,
  &__textarea {
    // background-color: $color-content-light;
    // color: $color-text-dark;
    // box-shadow: inset 0 0 0 0 rgba(0, 0, 0, 35%);
    // font-size: $font-size-default;
    // padding: 1rem;
    // border-radius: 4px;
    // -webkit-border-radius: 4px;
    // border: 1px solid $border-input-lines;
    // width: 100%;
    // box-sizing: border-box;
    font-family: "Inter";
    background-color: $color-content-light;
    color: $color-text-dark;
    font-size: $font-size-default;
    //padding: 1rem;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    border: 1px solid $border-input-lines;
    width: 100%;
    box-sizing: border-box;

    @include respond(big-phone){
      font-size: 16px!important;
    }

    &:focus {
      outline: none;
      padding: 0.92rem;
      @include themify($themes) {
        border: 2px solid themed('inputFocusColor');
      }
      border-radius: 4px;
    }
    &:active {
      background-color: #fafafa;
      color: rgba(0, 0, 0, 0.87);
      box-shadow: none;
    }

    //   &:focus:invalid {

    //   }

    //   &::-webkit-input-placeholder {

    //   }
  }
  &__input {
    padding: 12px;
    height: 45px;
    &:focus {
      outline: none;
      padding: 11px;
      border: 2px solid $color-text-light;
      border-radius: 4px;
    }
  }
  &__textarea {
    padding: 12px;
    &:focus {
      outline: none;
      padding: 11px;
      border: 2px solid $color-text-light;
      border-radius: 4px;
    }
  }
  &__select {
    padding: 11px;
    height: 45px;
    &:focus {
      outline: none;
      padding: 10px;
      border: 2px solid $color-text-light;
      border-radius: 4px;
    }
  }
  &__date-picker {
    position: relative;
    padding: 12px;

    &:focus {
      outline: none;
      padding: 11px;
      border: 2px solid $color-text-light;
      border-radius: 4px;
    }
    &::-webkit-calendar-picker-indicator{
      color: transparent;
      background: none;
      z-index: 1;
    }
    &::before {
      content: "";
      display: block;
      position: absolute;
      top: 0.8rem;
      right: 1rem;
      height: 20px;
      width: 20px;
      background-image: url(../../assets/icon/calendar.svg);
    }
  }
  &__span {
    position: absolute;
    // top: 0.75rem;
    right: 12%;
    color: $color-text-light;
    //background-color: $color-content-light;

    @include respond(tab-land) {
      // top: 0.5rem;
      left: unset;
      right: 7rem;
    }
  }
  &__label {
    font-size: $font-size-default;
    //margin-bottom: 0.7rem;
    display: block;
    transition: all 0.3s;
  }
  //   &__input:placeholder-shown + &__label {
  //     opacity: 0;
  //     visibility: hidden;
  //     transform: translateY(-4rem);
  //   }

  &__checkbox-groups-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include respond(tab-land) {
      flex-direction: column;
      align-items: flex-start;
    }
  }
  &__checkbox-group {
    margin-top: 1rem;
    display: flex;
    align-items: center;
  }
  &__checkbox {
    position: absolute;
    opacity: 0;
    // width: 1.5rem;
    // height: 1.5rem;
    // border-radius: 4px;
    // border: 1px solid $border-input-lines;
    // background-color: $color-content-light;
    & + label {
      position: relative;
      cursor: pointer;
      padding: 0;
      @include respond(big-phone) {
        font-size: 16px;
      }
      @include respond(mini-phone) {
        font-size: 12px;
      }
      &:focus {
        outline: none;
      }
    }

    // Box.
    & + label:before {
      content: '';
      margin-right: 10px;
      display: inline-block;
      vertical-align: text-top;
      width: 20px;
      height: 20px;
      background: $color-content-light;
      border-radius: 4px;
      border: 1px solid $border-input-lines;
    }
    //   // Box focus
    // &:focus + label:before {
    //   box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
    // }

    // Box checked
    &:checked + label:before {
      @include themify($themes) {
        background-color: themed('checkboxColor');
        // color: themed('primaryBtnTextColorHover');
      }
      // background: $color-link-dark;
    }

    // Disabled state label.
    &:disabled + label {
      color: #b8b8b8;
      cursor: auto;
    }

    // Disabled box.
    &:disabled + label:before {
      box-shadow: none;
      background: #ddd;
    }

    // Checkmark. Could be replaced with an image
    &:checked + label:after {
      content: '';
      position: absolute;
      left: 5px;
      top: 12px;
      background: $color-white;
      width: 2px;
      height: 2px;
      box-shadow: 2px 0 0 $color-white, 4px 0 0 $color-white,
        4px -2px 0 $color-white, 4px -4px 0 $color-white,
        4px -6px 0 $color-white, 4px -8px 0 $color-white;
      transform: rotate(45deg);
    }
  }
  // &__checkbox-label{
  //   margin-left: .7rem;
  // }
  &__radio-group {
    margin: 1rem 0;
  }
  &__radio-input {
    display: none;
  }
  &__radio-label {
    font-size: $font-size-default;
    cursor: pointer;
    position: relative;
    padding-left: 2.5rem;
    @include respond(tab-land) {
      font-size: 16px;
      padding-left: 3.5rem;
    }

    @include respond(phone) {
      display: flex;
      align-items: center;
    }
  }
  &__radio-button {
    height: 1.5rem;
    width: 1.5rem;
    border: 1px solid $border-input-lines;
    border-radius: 100%;
    display: inline-block;
    position: absolute;
    left: 0;
    top: 0;
    @include respond(tab-land) {
      height: 2.5rem;
      width: 2.5rem;
      display: flex;
    }

    &::after {
      content: '';
      display: block;
      height: 1.5rem;
      width: 1.5rem;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: $color-white;
      border: 6px solid $color-link-light;
      opacity: 0;
      transition: opacity 0.2s;
      @include respond(tab-land) {
        height: 2.5rem;
        width: 2.5rem;
        border: 8px solid $color-link-light;
      }
    }
  }
  &__radio-input:checked ~ &__radio-label &__radio-button::after {
    opacity: 1;
  }

  &__footer {
    display: flex;
    padding: 2rem 1.5rem;
    margin-top: 2rem;
    background-color: $color-content-light;
    border-radius: 0 0 4px 4px;
  }

  &__map {
    position: relative;
    width: 100%;
    height: 30rem;
    border-radius: 4px;
    border: 1px solid $border-input-lines;
  }
  &__file {
    position: relative;
    //color: transparent;
    input[type] {
      &::-webkit-file-upload-button {
        visibility: hidden;
        position: absolute;
        left: 0;
      }

      &::after {
        content: "izaberite";
        display: inline-block;
        outline: none;
        color: $color-text-dark;
        padding: 0.2rem 0.6rem;
        white-space: nowrap;
        -webkit-user-select: none;
        position: absolute;
        width: 6rem;
        height: 2rem;
        top: -0.25rem;
        right: 1rem;
        font-size: 12px;
        background-color: #c4c4c4;
        border-radius: 4px;
        cursor: pointer;

        @include respond(big-desktop) {
          padding: 0.4rem 0.9rem;
        }
        @include respond(tab-land) {
          padding: 0.2rem 0.6rem;
          top: 0.25rem;
          width: 6.5rem;
        }
      }
      &:valid {
        //position: absolute;
        left: 1rem;
      }
    }
  }
}
