.grid-view {
  display: flex;
  align-items: flex-start;
  margin-top: 1.5rem;

  @include respond(phone) {
    width: 24%;
    justify-content: flex-end;
  }

  .btn {
    background-color: $color-background-light;
    padding: 0.5rem;
    margin-left: 1rem;

    &:focus{
      outline: none;
    }

    svg {
      stroke: $color-background-dark;
      path {
        stroke: $color-background-dark;
      }
    }
  }
  .active {
    @include themify($themes) {
      background-color: themed(gridViewActiveBgColor) !important;
    }
    svg {
      @include themify($themes) {
        stroke: themed(gridViewActiveColor) !important;
      }
      path {
        @include themify($themes) {
          stroke: themed(gridViewActiveColor) !important;
        }
      }
    }
  }
}
