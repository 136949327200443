.dashboard {
    position: relative;

    &__title{
        h1{
            margin-bottom: 0;
            @include respond(phone){
                margin-bottom: 2rem;
            }
        }
    }
    &__subtitle{
        display: flex;
        justify-content: space-between;
        align-items: center;
        @include respond(tab-port){
            align-items: unset;
        }
        @include respond(phone){
            flex-direction: column;
        }
        &--left{
            display: flex;
            // width: 48%;
            @include respond(tab-port){
                flex-direction: column;
            }
            @include respond(phone){
                width: 80%;
                margin-bottom: 1rem;
            }
            p.left,p.right{
                display: flex;
            }
            p.left{
                margin-right: 1rem;
                @include respond(tab-port){
                    margin-bottom: 1rem;
                }
            }
        }
        .input-wrapper{
            position: relative;
            width: 50%;
            @include respond(tab-port){
                width: 100%;
            }
            @include respond(phone){
                width: 60%;
            }
        }
        &--right{
            position: relative;
            display: flex;
            align-items: center;
            @include respond(tab-port){
                flex-direction: column;
            }
            @include respond(phone){
                align-items: unset;
            }
            p{
                display: flex;
                // width: 50%;
                margin-right: 1rem;
              
                @include respond(tab-port){
                    width: 100%;
                    margin-right: 0;
                }
            }
            input {
                width: 100%;
               
            }
            span {
                position: absolute;
                top: .8rem;
                right: 1rem;
                height: 20px;
                width: 20px;
                background-image: url(../../assets/icon/calendar.svg);
            }
        }
    }
}
