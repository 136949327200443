.pr {
  position: relative;
}
.txt-up {
  text-transform: uppercase;
}
.txt-right {
  text-align: right;
}
.txt-left {
  text-align: left !important;
}
.txt-center {
  text-align: center;
}
.pr-0 {
  padding-right: 0 !important;
}
.cl {
  color: $color-text-light;
}
.cd {
  color: $color-text-dark;
}
.fw-500 {
  font-weight: 500;
}
.fw-600 {
  font-weight: 600;
}
.lh-sm {
  line-height: 1.5rem;
}
.list {
  list-style: none;
  margin: 1.5rem 0;
}
.item {
  margin-bottom: 0.5rem;
}
.h-100 {
  height: 100%;
  @include respond(phone) {
    height: auto !important;
  }
}
.h-70 {
  height: 70%;
}
.h-10r {
  height: 10rem;
}
.w-100 {
  width: 100%;
}
.w-100-imp {
  width: 100% !important;
}
.w-62 {
  width: 62% !important;
  @include respond(tab-land) {
    width: 100% !important;
  }
}
.w-60 {
  width: 60% !important;
}
.w-53 {
  width: 53% !important;
}
.w-48 {
  width: 48% !important;
}
.w-50-tabp-w-100 {
  width: 50;
  @include respond(tab-port) {
    width: 100%;
  }
}
.half-wd-full-tab {
  width: 48% !important;

  @include respond(tab-land) {
    width: 100% !important;
  }
}
.half-wd-full-tabp {
  width: 48% !important;

  @include respond(tab-port) {
    width: 100% !important;
  }
}
.tab-p-w-100 {
  @include respond(tab-port) {
    width: 100% !important;
  }
}
.pt-12 {
  padding-top: 1.2rem;
}
.w-40 {
  width: 40%;
}
.mob-w-100-d-25 {
  width: 25% !important;
  @include respond(phone) {
    width: 100% !important;
  }
}
.w-80 {
  width: 80% !important;
}
.w-75 {
  width: 75% !important;
}
.w-45 {
  width: 45% !important;
}
.w-35 {
  width: 35% !important;
}
.w-33 {
  width: 33% !important;
}
.w-30 {
  width: 30% !important;
}
.w-28 {
  width: 28%;
}
.w-20 {
  width: 20% !important;
}
.w-23 {
  width: 23% !important;
}
.w-25 {
  width: 25%;
}
.w-15 {
  width: 15% !important;
}
.w-7 {
  width: 7% !important;
}
.w-5 {
  width: 5% !important;
}
.h-100 {
  height: 100%;
}
.h-83 {
  height: 83%;
}
.mb-25 {
  margin-bottom: 2.5rem;
}
.mb-30 {
  margin-bottom: 3rem;
}
.mb-12 {
  margin-bottom: 1.2rem;
  @include respond(phone) {
    margin-bottom: 0;
  }
}
.mt-0 {
  margin-top: 0;
}
.mt-50 {
  margin-top: 5rem;
}
.mt-30 {
  margin-top: 3rem;
}
.mt-12 {
  margin-top: 1.2rem;
}
.mt-15 {
  margin-top: 1.5rem;
}
.mt-25 {
  margin-top: 2.5rem;
}
.mt-20 {
  margin-top: 2rem;
}
.mt-80 {
  margin-top: 8rem;
}
.mtb-20 {
  margin: 2rem 0;
}
.mtb-25 {
  margin: 2.5rem 0;
}
.mtb-30 {
  margin: 3rem 0;
}
.mtb-50 {
  margin: 5rem 0;
}
.mb-0 {
  margin-bottom: 0;
}
.mb-20 {
  margin-bottom: 2rem;
}
.mb-25 {
  margin-bottom: 2.5rem;
}
.mb-15 {
  margin-bottom: 1.5rem;
}
.mb-10 {
  margin-bottom: 1rem;
}
.ml-10 {
  margin-left: 1rem;
}
.ml-15 {
  margin-left: 1.5rem;
}
.mr-35 {
  margin-right: 3.5rem;
}
.mr-15 {
  margin-right: 1.5rem;
}
.mr-m {
  margin-right: 2rem;
}
.mr-xs {
  margin-right: 0.5rem;
}
.ml-s {
  margin-left: 0.5rem;
}
.ml-m {
  margin-left: 2rem;
}
.ml-l {
  margin-left: 3rem;
}
.ml-xl {
  margin-left: 6rem;
}
.mob-ml-0 {
  @include respond(phone) {
    margin-left: 0;
  }
}
.tabp-w-100 {
  @include respond(tab-port) {
    width: 100%;
  }
}
.tabp-w-49 {
  @include respond(tab-port) {
    width: 49% !important;
  }
}
.tabp-flex-wrap {
  @include respond(tab-port) {
    flex-wrap: wrap;
  }
}
.tabp-mtb-20 {
  @include respond(tab-port) {
    margin: 2rem 0;
  }
}
.tab-p-ml-0 {
  @include respond(tab-port) {
    margin-left: 0;
  }
}
.tabp-fd-c {
  @include respond(tab-port) {
    flex-direction: column;
  }
}
.mob-ml-20 {
  @include respond(phone) {
    margin-left: 2rem;
  }
}
.tabp-mb-20 {
  @include respond(tab-port) {
    margin-bottom: 2rem;
  }
}
.b-mob-mb-20 {
  @include respond(big-phone) {
    margin-bottom: 2rem;
  }
}
.mob-mb-20 {
  @include respond(phone) {
    margin-bottom: 2rem;
  }
}
.mob-mb-0 {
  @include respond(phone) {
    margin-bottom: 0!important;
  }
}
.mr-s {
  margin-right: 0.5rem;
}
.mr-15-tabp-0 {
  margin-right: 1.5rem;
  @include respond(tab-port) {
    margin-right: 0;
  }
}
.df {
  display: flex;
}
.fd-column {
  flex-direction: column;
}
.ai-c {
  align-items: center;
}
.ai-end {
  align-items: flex-end;
}
.ai-start {
  align-items: flex-start;
}
.jc-end {
  justify-content: flex-end;
}
.jc-sb {
  justify-content: space-between;
}
.as-end {
  align-self: flex-end;
}
.jc-center {
  justify-content: center !important;
}
.tab-mb-20 {
  @include respond(tab-port) {
    margin-bottom: 2rem;
  }
}
.mob-mb-10 {
  @include respond(phone) {
    margin-bottom: 1rem;
  }
}
.mob-mt-10 {
  @include respond(phone) {
    margin-top: 1rem;
  }
}
.mob-mt-20 {
  @include respond(phone) {
    margin-top: 2rem;
  }
}
.mob-mr-10 {
  @include respond(phone) {
    margin-right: 1rem;
  }
}
.mob-mr-0 {
  @include respond(phone) {
    margin-right: 0;
  }
}
.mob-ml-10 {
  @include respond(phone) {
    margin-left: 1rem;
  }
}
.mob-fd-column {
  @include respond(phone) {
    flex-direction: column;
  }
}
.tabp-fd-column {
  @include respond(tab-port) {
    flex-direction: column;
  }
}
.tabp-none {
  @include respond(tab-port) {
    display: none;
  }
}
.mob-ai-start {
  @include respond(phone) {
    align-items: flex-start !important;
  }
}
.mob-w-100 {
  @include respond(phone) {
    width: 100% !important;
  }
}
.b-mob-w-100 {
  @include respond(big-phone) {
    width: 100% !important;
  }
}
.dshow {
  display: block;
  @include respond(tab-land) {
    display: none !important;
  }
}
.dshow-cell {
  display: table-cell;
  @include respond(tab-land) {
    display: none !important;
  }
}
.tshow {
  display: none;
  @include respond(tab-land) {
    display: block;
  }
}
.m-none {
  display: block;
  @include respond(phone) {
    display: none !important;
  }
}
.bm-none {
  display: block;
  @include respond(big-phone) {
    display: none !important;
  }
}
.bm-show {
  display: none!important;
  @include respond(big-phone) {
    display: block !important;
  }
}
.tab-ml-10p {
  margin-left: 0;
  @include respond(tab-land) {
    margin-left: 10%;
  }
  @include respond(phone) {
    margin-left: 0;
  }
}
.d-none {
  display: none;
}
.mob-absolute-topright {
  @include respond(big-phone) {
    position: absolute;
    top: 0;
    right: 0;
  }
}
.mob-relative-block {
  @include respond(big-phone) {
    position: relative;
    display: block;
  }
}
.mob-txt-left {
  @include respond(big-phone) {
    text-align: left!important;
  }
}
.bg-light {
  background-color: $color-background-light !important;
}

.react-datepicker {
  font-family: inherit;
  font-size: $font-size-default;
  background-color: $color-white;
  color: $color-text-dark;
  // border: 1px solid #aeaeae;
  border: none;
  border-radius: 4px;
  display: inline-block;
  position: relative;
  box-shadow: 0px 4px 6px rgba($color-black, 0.2);
  @include respond(phone) {
    font-size: 16px;
  }
  &__header {
    background-color: $color-background-input;
    border-bottom: 1px solid $color-content-lines;
    border-top-left-radius: 4px;
    padding-top: 8px;
    position: relative;
  }
  &__current-month {
    font-size: 18px;
  }
  &__day {
    width: 3rem;
    line-height: 3rem;
    &--today,
    &--keyboard-selected {
      background: $color-hover;
      &:hover {
        background: $color-hover;
      }
    }
    &--in-range {
      background-color: $color-hover;
    }
    &--in-selecting-range {
      background-color: rgba($color-hover, 0.5);
      &:hover {
        background-color: $color-hover;
      }
    }
  }
  &__day-name {
    width: 3rem;
  }
  &__triangle::before {
    border-bottom-color: $color-background-input !important;
  }
}
.w-break {
  word-break: break-all;
}
.w-break-unset {
  word-break: unset !important;
}
.flex-nowrap {
  flex-wrap: nowrap;
}
.lowercase {
  text-transform: lowercase;
}
.date-width {
  width: 15% !important;
  @include respond(tab-land) {
    width: 20% !important;
  }
  @include respond(tab-port) {
    width: 25% !important;
  }
}
.no-outline {
  :focus {
    outline: none !important;
  }
}
.visible {
  opacity: 1 !important;
  visibility: visible !important;
}
.error-text {
  border: 1px solid red;
  border-radius: 4px;
}
.error {
  color: red;
}
.h-auto {
  height: auto !important;
}
.nowrap {
  white-space: nowrap;
}
// border dark
.bd{
  &__bottom {
    border-bottom: 1px solid #000;
  }
  &__top {
    border-top: 1px solid #000;
  }
  &__all {
    border: 1px solid #000;
  }
}
.bl{
  &__bottom {
    border-bottom: 1px solid $color-content-lines;
  }
  &__top {
    border-top: 1px solid $color-content-lines;
  }
  &__all {
    border: 1px solid $color-content-lines;
  }
}
// vertical-align: middle
.vm{
  vertical-align: middle;
}
.vs{
  vertical-align: super;
}

.pointer{
  cursor: pointer;
}
