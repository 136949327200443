.screen-content-info {
  .p-margin {
    @include respond(phone) {
      margin: auto 2.5rem;
      text-align: center;
      padding-bottom: 2rem;
    }
  }
  .main-content__box {
    display: flex;

    @include respond(tab-land) {
      flex-direction: column;
    }
  }
  .side-info {
    width: 30%;

    @include respond(tab-land) {
      position: absolute;
      display: none !important;
      flex-wrap: wrap;
      width: 100%;
      z-index: 1000;
      box-shadow: 0 11px 18px rgba($color-black,.2);
      //min-height: 100%;
      &.show {
        display: flex !important;
      }
    }
  }
  .back-link {
    margin-top: 4rem;

    svg {
      margin-right: 2rem;
      transition: all 0.3s ease-in-out;
      -webkit-transition: all 0.3s ease-in-out; /** Chrome & Safari **/
      -moz-transition: all 0.3s ease-in-out; /** Firefox **/
      -o-transition: all 0.3s ease-in-out; /** Opera **/
    }
    &:hover {
      svg {
        transform: translate(-0.5rem, 0);
        -webkit-transform: translate(-0.5rem, 0); /** Chrome & Safari **/
        -o-transform: translate(-0.5rem, 0); /** Opera **/
        -moz-transform: translate(-0.5rem, 0); /** Firefox **/
      }
    }
  }
  .content {
    width: 70%;
    @include respond(tab-land) {
      width: 100%;
    }
  }
}
