//COLORS
$color-primary: #6B7280;
$color-primary-600: #4B5563;

//background
$color-background-lighter:#F3F4F6;
$color-background-light:#E6E6E6;
$color-background-dark:#6B7280;
$color-background-input: #F3F4F6;
//end background

//text
$color-text-light:#6B7280;
$color-text-dark: #111827;
//end text

//link
$color-link-light:#2998ff;
$color-link-dark:#2563EB;
//end link
// icon
$color-sidebar-icon:#9CA3AF;

//onhover
$color-hover:#D1D5DB;
$color-check-badge: #3B82F6;

$color-danger-dark: #B91C1C;
$color-danger-light: #FEE2E2;
$color-danger-600: #DC2626;
$color-success-dark: #166534;
$color-success-600: #16A34A;
$color-success-light: #DCFCE7;
$color-warning-light: #FFEDD5;

$border-input-lines: #D1D5DB;
$color-content-light:#F9FAFB;
$color-content-lines:#E5E7EB;

$color-grey-light-1: #f7f7f7;
$color-grey-light-2: #eee;
$color-grey-light-3: #e0e0e0;
$color-grey-light-4: #afafaf;

$color-grey-dark: #777;
$color-grey-dark-2: #999;
$color-grey-dark-3: #333;

$color-white: #fff;
$color-black: #000;

//FONT
$font-size-default: 14px;
$font-size-default-tab: 16px;
$font-size-grid-view: 18px;
$font-size-primary: 28px;
$font-size-secondary: 24px;
$font-size-tertiary: 16px;
$font-size-quatenary: 12px;