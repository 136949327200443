@import 'styles/abstract/colors';
@import 'styles/abstract/functions';
@import 'styles/abstract/mixins';
@import 'styles/abstract/themes';
@import 'styles/abstract/variables';

@import 'styles/base/base';
@import 'styles/base/typography';
@import 'styles/base/utility';

@import 'styles/font/inter-inconsolata.scss';

@import 'styles/components/box';
@import 'styles/components/button';
@import 'styles/components/dropdown-list';
@import 'styles/components/filter';
@import 'styles/components/floating-button';
@import 'styles/components/form';
@import 'styles/components/grid-view';
@import 'styles/components/icon';
@import 'styles/components/img-round';
@import 'styles/components/logo';
@import 'styles/components/main-title';
@import 'styles/components/menu-icon';
@import 'styles/components/modal';
@import 'styles/components/pagination';
@import 'styles/components/search';
@import 'styles/components/select-field';
@import 'styles/components/side-info';
@import 'styles/components/sidebar-badge';
@import 'styles/components/stepper';
@import 'styles/components/table-grid-view';
@import 'styles/components/table';
@import 'styles/components/tag';
@import 'styles/components/user-nav';
@import 'styles/components/filepond';

@import 'styles/layout/fiscal-bill';
@import 'styles/layout/footer';
@import 'styles/layout/main-content';
@import 'styles/layout/header';
@import "styles/layout/invoice-template-1";
@import 'styles/layout/invoice';
@import 'styles/layout/item-list';
@import 'styles/layout/login';
@import 'styles/layout/grid';
@import 'styles/layout/screen-content-info';
@import 'styles/layout/screen-content';
@import 'styles/layout/section-box';
@import 'styles/layout/sidebar';
//@import "styles/layout/invoice-template-2";
//@import "styles/layout/invoice-template-3";

@import 'styles/pages/home';
@import 'styles/pages/dashboard.scss';
@import 'styles/pages/companies.scss';
@import 'styles/layout/print-template-izvjestaji';