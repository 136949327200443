$transition-time: 0.4s;
$rotation: 45deg;
$translateY: (50px / 2);
$translateX: 0;

.menu-icon {
  cursor: pointer;
  height: 48px;
  transition: 0.4s cubic-bezier(0, 0, 0, 1);
  user-select: none;
  width: 48px;
  outline: none;
  display: none;

  @include respond(tab-land) {
    display: block;
    position: absolute;
    top: 1rem;
    left: 1rem;
  }
  @include respond(big-phone){
    top: .8rem;
    z-index: 100000;
    // left: 10px;
  }

  .line {
    background: currentcolor;
    height: 3px;
    left: 6px;
    position: absolute;
    width: 36px;

    transition: all 0.4s cubic-bezier(0, 0, 0, 1) 0s;
    transform: none;

    &.line-1 {
      top: 16px;
    }
    &.line-2 {
      top: 29px;
    }
  }
  &.active {
    .line-1 {
      transform: rotate(45deg) translate(4.59px, 4.59px);
    }
    .line-2 {
      transform: rotate(-45deg) translate(4.59px, -4.59px);
    }
  }
  &.active-mob {
    
  }
}
