.side-info {
  position: relative;
  display: block;
  //min-height: 100vh;
  padding: 2rem 1rem;
  background-color: $color-content-light;
  border-left: 1px solid $color-content-lines;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;

  &__wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;

    h1 {
      margin: unset;
      margin-bottom: 1.5rem;
    }

    @include respond(tab-land) {
      width: 50%;
      margin: 2rem 0;
    }
    @include respond(phone) {
      width: 100%;
    }
  }
  &__title {
    position: relative;
    display: block;
    width: 90%;
    span{
      max-width: 90%;
    }
    i {
      position: absolute;
      margin-top: .5rem;
      margin-left: .5rem;
    }
  }
  &__logo-wrapper {
    display: flex;
    justify-content: flex-start;
    height: 8rem;
  }
  // &__logo {
  //   background-position: center;
  //   background-repeat: no-repeat;
  //   background-size: contain;
  //   height: 8rem;
  //   width: 100%;
  //   margin-bottom: 1rem;
  //   @include respond(tab-land) {
  //     background-position: left;
  //   }
  // }
  &__desc {
    margin-bottom: 2.5rem;
    @include respond(tab-land) {
      width: 95%;
    }
  }
  &__info {
    display: flex;
    width: 100%;
    flex-direction: column;
    //align-items: center;
    @include respond(tab-land) {
      //align-items: center;
      //align-self: flex-end;
      height: 100%;
      width: 90%;
    }
    @include respond(phone) {
      align-self: unset;
      width: 100%;
    }
    .col-l {
      color: $color-text-light;
      // width: 40%;
    }
    .col-r {
      text-align: right;
      // width: 60%;
      word-break: break-all;
    }
    &--inner-wrapper {
      display: flex;
      justify-content: space-between;
      //align-items: center;
      width: 100%;
    }
  }
  &__map {
    height: 11rem;
    width: 100%;
    border: 1px solid $color-hover;
    border-radius: 4px;
    background-position: center;
    background-size: cover;
    margin-bottom: 2rem;

    @include respond(tab-land) {
      height: 33rem;
    }
  }
  h3 {
    margin-bottom: 1.5rem;
  }
  &__close {
    position: absolute;
    top: 1rem;
    right: 1rem;
    cursor: pointer;
  }
  .danger {
    color: $color-danger-dark;
    margin-bottom: 0;
    margin-left: .2rem;
    // svg {

    // }
  }
  // .spn-mr-10 {
  //   margin-right: 0.5rem;
  // }
}
