// .fiscal-bill {
//   position: relative;
//   font-family: 'Inconsolata', monospace !important;
//   font-size: 24px;
//   background-color: $color-white;
//   height: 100%;
//   padding: 1rem;

//   &__header {
//     position: relative;
//     &--logo {
//       text-align: center;
//     }
//     &--info {
//       margin-bottom: 2rem;
//     }
//     p {
//       text-align: center;
//     }
//     p:nth-last-child {
//       margin-bottom: 2rem;
//     }
//   }
//   &__body {
//     table {
//       h2 {
//         font-size: 30px;
//         font-weight: 700;
//         margin-bottom: 4rem;
//       }
//       border-top: 1px solid $color-black;
//       width: 100%;
//       tr:first-child {
//         border-top: 1px solid $color-black;
//       }
//       td {
//         border-bottom: none;
//       }
//       td {
//         padding: 0;
//         vertical-align: top;
//       }
//       .left {
//         width: 75%;
//       }
//     }
//   }
//   &__footer {
//     position: relative;
//     &--qr-code {
//       text-align: center;
//       margin-top: 2rem;
//       img {
//         height: 12rem;
//         width: 12rem;
//       }
//     }
//     p {
//       font-size: 18px !important;
//     }
//   }

//   p {
//     font-size: 24px;
//     line-height: 25px;
//   }
// }

.fiscal-bill {
  position: relative;
  font-family: "Inconsolata", monospace !important;
  font-size: 15pt;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;

  &__header {
    position: relative;
    &--logo {
      text-align: center;
      height: 100pt;
    }
    &--info {
      margin-bottom: 15pt;
    }
    p {
      text-align: center;
    }
    p:nth-last-child {
      margin-bottom: 15pt;
    }
  }
  &__body {
    .table-fiscal {
      // border: none;
      width: 100%!important;
      h2 {
        font-size: 16pt;
        font-weight: 700;
        margin-bottom: 30pt;
      }
      border-top: 1pt solid $color-black;
      width: 100%;
      tr {
        border: none!important;
      }
      tr:first-child {
        border-top: 1pt solid $color-black!important;
      }
      td {
        font-weight: 400!important;
        border: none!important;
      }
      td {
        padding: 0;
        vertical-align: top;
      }
      .left {
        width: 60%;
      }
      .right {
        width: 40%;
      }
    }
  }
  &__footer {
    position: relative;
    &--qr-code {
      text-align: center;
      margin-top: 15pt;
      img {
        height: 80pt;
        width: 80pt;
      }
    }
    p {
      font-size: 8pt !important;
    }
  }

  p {
    font-size: 15pt;
    line-height: 20pt;
  }
}
.fiscal-page {
  position: relative;
  width: 80mm;
  min-height: 207mm;
  padding: 2mm;
  margin: 10mm auto!important;
  border: 1pt #d3d3d3 solid;
  border-radius: 5pt;
  background: #fff;
  box-shadow: 0 0 5pt rgba(0, 0, 0, 0.1);
}


// @media print and (min-width: 2in) and (max-width: 4in) {
//   html,
//   body {
//     max-width: 4in!important;
//     width: 2in!important;
//     margin: 0mm!important;
//     height: 100%;
//   }
// }
// @media print and (min-width: 4.1in) and (max-width: 6in) {
//   /* index card styling */
// }

// @media print and (min-width: 7in) and (min-height: 13in) {
//   /* legal-size styling */
// }
// @media print and (min-width:210mm) and (min-height: 105mm) {
//   html,
//   body {
//     width: 210mm!important;
//     margin: 0mm!important;
//     height: 105mm!important;
//     .fiscal-page {
//       margin: 0 !important;
//       padding: 3mm !important;
//       border: initial !important;
//       border-radius: initial !important;
//       width: 210mm !important;
//       min-height: initial;
//       box-shadow: initial;
//       background: #fff !important;
//       page-break-after: always !important;
//     }
//   }
// }
@media print {
  html,
  body {
   max-width: 210mm;
    width: 100%!important;
    // min-height: 297mm;
    height: 100%;
    margin: 0mm!important;
    background: #fff;
   // position: absolute;

    // @page {
    //   size: 52mm auto!important;
    //   margin: 0mm!important;

    //   .fiscal-page {
    //     margin: 0 !important;
    //     padding: 3mm !important;
    //     border: initial !important;
    //     border-radius: initial !important;
    //     width: 52mm !important;
    //     min-height: initial;
    //     box-shadow: initial;
    //     background: #fff !important;
    //     page-break-after: always !important;
    //   }
    // }
    .fiscal-page {
      font-family: "Inconsolata", monospace !important;
      margin: 0 !important;
      padding: 3mm !important;
      border: initial !important;
      border-radius: initial !important;
      width: 74mm !important;
      min-height: initial;
      box-shadow: initial;
      background: #fff !important;
      page-break-after: always !important;
    }

    .table-fiscal {
      display: table !important;
      table-layout: fixed !important;
      page-break-inside: auto !important;
      margin-left: auto !important;
      margin-right: auto !important;
      border-collapse: collapse !important;
      border-style: none !important;
      border-width: 0!important;
      border-spacing: 0 !important;
      font-size: 11pt !important;
      width: 100% !important;

      tr {
        display: table-row;
        page-break-inside: avoid;
        page-break-after: auto;
        border: solid!important;
        border-style: none !important;
        // width: 100%;
       border-width: 0!important;
        border-top: 1pt solid #fff!important;
      }
      td {
        display: table-cell;
        border-style: none !important;
        border-width: 0 !important;
        text-align: left !important;
        border-style: none !important;
      }
    }
  }
}

