.user-nav {
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-left: 2rem;
  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 1rem;
    cursor: pointer;
    svg {
      transition: stroke 0.1s ease-in-out;
    }
    &:hover svg {
      stroke: $color-background-dark;
    }
  }
  &__profile {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    cursor: pointer;
    // transition: all .1s;

    &--text {
      font-size: 14px;
      margin-right: 1rem;
      @include respond(big-phone) {
        display: none;
      }
    }
    &--img {
      position: relative;
      width: 36px;
      height: 36px;
      background-position: center;
      background-size: cover;
      border-radius: 100%;
      cursor: pointer;

      ul {
        list-style: none;
      }
    }
    &:hover {
      border-top-right-radius: 4px;
      border-top-left-radius: 4px;
      border-bottom-right-radius: 0px;
      border-bottom-left-radius: 0px;
      @include themify($themes) {
        background-color: themed('profileBgHover');
        color:themed('profileTextHover');
      }
      .drop-down {
        top: 5.7rem;
        right: 0;
        display: block;
        @include respond(big-desktop) {
          top: 5rem;
        }
        @include respond(tab-land) {
          top: 6rem;
        }
        @include respond(tab-port) {
          top: 6rem;
        }
        // svg{
        //   stroke: $color-text-light;
        // }
      }
    }
  }
}
// .user-nav__profile:hover .user-nav__drop-down{display: block;}
