.btn {
  display: flex;
  //justify-content: space-between;
  align-items: center;
  font-size: $font-size-default;
  font-weight: 500;
  padding: .8rem 1.2rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &__light-dd {
    background-color: $color-background-light;
    color: $color-text-dark;
    transition: all 0.1s ease-in-out;
    svg {
      transition: stroke 0.1s ease-in-out;
    }
    &:hover {
      @include themify($themes) {
        background-color: themed('btnLightColor');
        color: themed('primaryBtnTextColor');
      }
      border-top-right-radius: 4px;
      border-top-left-radius: 4px;
      border-bottom-right-radius: 0px;
      border-bottom-left-radius: 0px;
      // background-color: rgba($color-background-light, 0.7);
      // color: rgba($color-text-dark, 0.7);
      svg {
        stroke: rgba($color-text-dark, 0.7);
      }
    }
    &:focus {
      outline: none;
    }
  }
  &__primary,
  &__primary-fill {
    @include themify($themes) {
      background-color: themed('primaryBtnBgColor');
      color: themed('primaryBtnTextColor');
    }
    display: flex;
    justify-content: center;
    height: 45px;
    transition: all 0.1s ease-in-out;

    &:hover {
      @include themify($themes) {
        background-color: themed('primaryBtnBgColorHover');
        color: themed('primaryBtnTextColorHover');
      }

      // svg {
      //   @include themify($themes) {
      //     stroke: themed('iconColorLightHover');
      //   }
      // }
    }
    &:focus {
      outline: none;
    }
    &__primary {
      svg {
        @include themify($themes) {
          stroke: themed('iconColorLight');
        }
        transition: stroke 0.1s ease-in-out;
      }
      &:hover {
        svg {
          @include themify($themes) {
            stroke: themed('iconColorLightHover');
          }
        }
      }
    }
    &__primary-fill {
      svg {
        @include themify($themes) {
          fill: themed('iconColorLight');
        }
        transition: stroke 0.1s ease-in-out;
      }
      &:hover {
        svg {
          @include themify($themes) {
            fill: themed('iconColorLightHover');
          }
        }
      }
    }
  }

  &__secondary {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    @include themify($themes) {
      border: 1px solid themed('secondaryBtnBorderColor');
      color: themed('secondaryBtnTextColor');
    }
    transition: all 0.1s ease-in-out;
    &:hover {
      @include themify($themes) {
        background-color: themed('secondaryBtnBgColorHover');
      }
    }
    &:focus {
      outline: none;
    }
  }

  &__close {
    padding: 0.5em;
    &.danger {
      color: $color-danger-dark;
    }
  }

  &__link {
    // display: inline-block!important;
    background-color: transparent;
    padding: 0;
    width: auto;
    font-size: $font-size-default;
    &.danger {
      margin-bottom: 2rem;
      margin-left: 1rem;
      color: $color-danger-dark;
      @include respond(phone) {
        margin-left: unset;
      }
    }
    &.success {
      color: $color-link-dark;
    }
    &:focus {
      outline: none;
    }
  }

  &__xl {
    width: 16rem;
    height: 4rem;
    font-size: $font-size-default;
    font-weight: 600;
    padding: 1rem;
  }
  &__lg {
    width: 13rem;
  }
  &__md {
    width: 8.5rem;
    height: 4rem;
    font-size: $font-size-default;
    font-weight: 600;
    align-items: center;
    justify-content: center;
  }
  &__xs {
    position: relative;
    justify-content: center;
    width: 3rem;
    height: 3rem;
    padding: 0.5rem;
    background-color: transparent;
    font-size: $font-size-default;

    &:hover {
      .drop-down {
        top: 3rem;
        right: 0;
        display: block;
        // svg{
        //   stroke: $color-white;
        // }
      }
    }
  }
}
