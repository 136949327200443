.pagination-wrapper{
    padding: 3rem 0;
}

.disabled-buttons {
  display: none;
}

.pagination {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  list-style-type: none;

  li {
    margin: 8px;
  }

  a, a:hover, a:focus, a:active {
    text-decoration: none;
    color: inherit;
    line-height: 54px;
    padding: 19px;
    border: none;
    border-radius: 4px;
    outline: none;
    cursor: pointer;
    background-color: transparent;
    transition: background-color 0.3s ease-in-out;
    text-align: center;

    &:hover {
      background-color: $color-hover;
    }
  }

  .active {
    background-color: $color-hover;
    //padding: 17px;
    border: none;
    border-radius: 4px;
    outline: none;
    cursor: pointer;
  }
}
