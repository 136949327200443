.table-wrapper-grid-view {
  display: flex;
  padding: 0 1.5rem;
}
.table-grid-view {
  width: 100%;
  border-collapse: collapse;
  border: none;
  font-size: $font-size-grid-view;
  tbody {
    display: flex;
    flex-wrap: wrap;
    // @include respond(phone){
    //   justify-content: space-between;
    // }
    tr {
      position: relative;
      display: flex;
      flex-direction: column;
      width: 16rem;
      height: 16rem;
      background-color: $color-content-light;
      border: 1px solid #eee;
      border-radius: 16px;
      margin-bottom: 0.5rem;
      margin-right: 0.5rem;
      padding: 1.2rem;
      overflow: hidden;
      cursor: pointer;
      transition: all .2s ease-in-out;

      @include respond(tab-land) {
        width: 20rem;
        height: 20rem;
        margin-bottom: 2.8rem;
        margin-right: 2.8rem;
      }
      @include respond(tab-port) {
        margin-bottom: 2.2rem;
        margin-right: 2.2rem;
      }
      @include respond(phone) {
        width: 16rem;
        height: 16rem;
        // margin-right: unset;
      }

      .stepper {
        position: absolute;
        bottom: -40px;
        left: 0;
        opacity: 0;
        transition: opacity 0.1.5s ease-in-out;
        transition: bottom 0.1.5s 0.1s ease-in-out;
      }
    }
    .active {
      position: relative;
      background: $color-content-lines;
      border: 2px solid $color-background-dark;
      width: 15.9rem;
      height: 15.9rem;
      td:nth-child(3) {
        bottom: 4.5rem;
      }
      .heading-quaternary{
        overflow-y: auto;
        max-height: 4rem;
      }
      @include respond(tab-land) {
        width: 19.9rem;
        height: 19.9rem;
      }
      @include respond(phone) {
        width: 15.9rem;
        height: 15.9rem;
      }

      .stepper {
        position: absolute;
        bottom: 10px;
        left: 0;
        justify-content: center;
        width: 100%;
        opacity: 1;

        @include respond(phone) {
          bottom: 4px;
        }
      }
    }
  }
  th {
    background-color: $color-content-light;
    color: white;
    font-weight: 500;
    span {
      display: flex;
      flex-direction: column;
      .desc {
        font-size: 10px;
      }
      .asc {
        font-size: 10px;
        transform: rotate(180deg);
      }
    }
    button {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      background-color: transparent;
      border: none;
      //color: white;
      outline: none;
      cursor: pointer;
    }
    input {
      border-radius: 5px;
      border: none;
      padding: 5px 10px;
      outline: none;
    }
  }
  td {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    .heading-quaternary{
      overflow-y: auto;
      max-height: 6rem;
    }

    p {
      width: 100%;
      font-size: $font-size-grid-view;
      @include respond(phone) {
        font-size: 14px;
        line-height: 15px;
      }
    }
    span {
      margin-right: 1rem;
    }
  }
  td:first-child {
    padding-top: unset;
  }
  th {
    padding: 1.5rem;
  }
  // th:first-child {
  //   width: 35%;
  //   @include respond(tab-land) {
  //     width: 40%;
  //   }
  // }
  // th:last-child {
  //   width: 20%;
  //   text-align: right;

  //   @include respond(tab-land) {
  //     width: 15%;
  //   }
  // }
  td:nth-child(2) {
    display: none;
  }
  td:nth-child(3) {
    position: absolute;
    bottom: 1rem;
  }
  td,
  th {
    text-align: left;
    border-bottom: none;
  }

  .inner-td-wrapper {
    display: flex;
    align-items: center;
  }
  .td-title {
    margin-left: 2rem;
  }
}
