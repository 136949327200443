.stepper {
  display: flex;
  justify-content: flex-end;

  @include respond(big-phone) {
    justify-content: unset;
    width: 10rem;
    border-radius: 4px;
  }

  &__btn-left {
    border-radius: 4px 0px 0px 4px;
    &:hover {
      border-radius: 4px 0px 0px 4px;
    }
  }
  &__btn-right {
    border-radius: 0px 4px 4px 0px;
    &:hover {
      border-radius: 0px 4px 4px 0px;
    }
  }
  &__btn-left,
  &__btn-right,
  &__btn {
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    @include themify($themes) {
      background-color: themed(stepperBtnBgColor) !important;
    }
    padding: 0.8rem;
    width: 3rem;
    transition: background-color 0.1.5s ease-in;
    cursor: pointer;
    svg {
      @include themify($themes) {
        stroke: themed(stepperBtnColor) !important;
      }
      width: 18px;
      height: 18px;
    }
    &:focus {
      outline: none;
    }
    &:hover {
      @include themify($themes) {
        background-color: themed(stepperBtnHoverBgColor) !important;
      }
      svg {
        @include themify($themes) {
          stroke: themed(stepperBtnHoverColor) !important;
        }
      }
    }
  }

  // svg {
  //   stroke: $color-background-light;
  //   width: 18px;
  //   height: 18px;
  // }

  &__btn {
    border-radius: 4px;
  }
  &__input {
    @include themify($themes) {
      background-color: themed(stepperInputBgColor) !important;
    }
    width: 4rem;
    border: none;
    text-align: center;
    &:focus {
      outline: none;
    }
  }
}
