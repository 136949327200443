.filter {
  display: flex;
  flex-wrap: wrap;
  width: 80%;
  align-items: center;
  margin-top: 1.5rem;
  max-height: 11rem;
  overflow-y: auto;

  @include respond(phone) {
    width: 76%;
  }
  &__tab {
    display: flex;
    align-items: center;
    justify-content: center;
    color: $color-text-dark;
    background-color: $color-background-light;
    font-weight: 500;
    border-radius: 17px;
    padding: 0.2rem 1rem;
    height: 2.2rem;
    margin-right: 1.5rem;
    margin-bottom: 1rem;
    cursor: pointer;
  }
  .active {
    @include themify($themes) {
      background-color: themed(filterActiveBgColor) !important;
      color: themed(filterActiveColor);
    }
  }
}
