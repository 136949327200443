#sidebar {
  @include respond(big-phone) {
    position: absolute;
    top: 0;
    left: 0;
  }
}
.sidebar {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-right: 4rem;
  margin-top: 3rem;
  min-height: 100vh;
  transition: width 0.25s linear;

  &.active {
    @include respond(tab-land) {
      width: 28rem;
    }
    @include respond(big-phone) {
      visibility: visible;
      opacity: 1;
      width: 100%;
      display: block;
    }
  }

  @include respond(tab-land) {
    position: absolute;
    left: 1rem;
    width: 6rem;
    margin-right: 0;
    margin-top: 13rem;
    h3 {
      font-size: 10px;
    }
  }
  @include respond(tab-port) {
    h3 {
      font-size: 8px;
    }
  }

  @include respond(big-phone) {
    visibility: hidden;
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    width: 100%;
    margin-top: 0;
    padding-top: 7rem;
    z-index: 10000;
    background-color: $color-background-light;
    overflow: hidden;
    transition: visibility 0.3s, opacity 0.2s linear;

    h3 {
      font-size: 16px;
      padding: 1rem 2rem;
    }
  }

  &__heading {
    color: $color-text-light;
    text-transform: uppercase;
    font-weight: 500;
    font-size: $font-size-quatenary;
    letter-spacing: 1.5px;

    @include respond(tab-land) {
      font-size: 8px;
      letter-spacing: unset;
    }
    @include respond(phone) {
      font-size: $font-size-tertiary;
    }
  }

  &__btn {
    display: none;

    @include respond(tab-land) {
      display: block;
      position: absolute;
      top: 1.5rem;
      left: 0;
    }
  }

  .items {
    list-style: none;
    margin-bottom: 3rem;
  }

  .item,
  h3 {
    padding: 1rem 1.5rem;
    @include respond(tab-land) {
      padding: 1rem 0;
    }
    @include respond(big-phone) {
      padding: 1rem 2rem;
    }
  }
  .item {
    position: relative;
    display: flex;
    margin-bottom: 0.5rem;
    cursor: pointer;
    background-color: transparent;

    &.active {
      border-radius: 4px;
      @include themify($themes) {
        background-color: themed('menuItemBgColorActive');
      }
      span {
        background-color: $color-background-light;
        color: $color-text-dark !important;
      }
      .sidebar-icon {
        @include themify($themes) {
          stroke: themed('menuItemIconColorActive');
        }
      }
      .link {
        @include themify($themes) {
          color: themed('menuItemTextColorActive');
        }
        color: $color-text-light;
      }

      @include respond(big-phone) {
        border-radius: unset;
      }
    }

    @include respond(tab-land) {
      border-radius: 4px;
      background-color: $color-hover;
      padding: 1rem;
      display: flex;
      align-items: center;
      max-height: 5rem;
    }

    @include respond(big-phone) {
      background-color: transparent;
      padding: 1rem 1.5rem;
    }

    .link {
      color: $color-text-dark;
      text-decoration: none;
      transition: opacity 0.5s ease-in;

      @include respond(tab-land) {
        visibility: hidden;
        opacity: 0;
        margin-left: 5rem;
        height: 3rem;
        font-size: 18px;
      }
      @include respond(phone) {
        visibility: visible;
        opacity: 1;
        font-size: 24px;
        margin-left: 2rem;
      }

      &.active {
        @include respond(tab-land) {
          visibility: visible;
          opacity: 1;
          margin-left: 5rem;
          height: 3rem;
        }
        @include respond(phone) {
          margin-left: 3rem;
          height: auto;
        }
      }
    }

    .sidebar-icon {
      display: flex;
      height: 22px;
      width: 22px;
      stroke: $color-sidebar-icon;
      margin-right: 2rem;

      @include respond(tab-land) {
        align-items: center;
        height: 36px;
        width: 36px;
        margin-right: 0;
        position: absolute;
      }
      @include respond(phone) {
        position: relative;
      }
    }

    &:hover {
      border-radius: 4px;

      @include themify($themes) {
        background-color: themed('menuItemBgColorHover');
      }
      .sidebar-badge {
        background-color: $color-background-light;
        color: $color-text-dark !important;

        @include respond(phone) {
        }
      }
      .sidebar-icon {
        @include themify($themes) {
          stroke: themed('menuItemIconColorHover');
        }
      }
      .link {
        @include themify($themes) {
          color: themed('menuItemTextColorHover');
        }
      }
      @include respond(big-phone) {
        border-radius: 0;
      }
    }
  }
}
