@mixin fontdef-inter($InterPath, /*$InterVersion,*/ $FontType: "Regular") {
	src: url("#{$InterPath}/#{$FontType}/Inter-#{$FontType}.woff2") format("woff2"),
		url("#{$InterPath}/#{$FontType}/Inter-#{$FontType}.woff") format("woff"),
		url("#{$InterPath}/#{$FontType}/Inter-#{$FontType}.otf") format("otf"),
		url("#{$InterPath}/#{$FontType}/Inter-#{$FontType}.ttf") format("ttf");
}


@mixin fontdef-inconsolata($MontserratPath, $FontType: "Regular") {
	src: url("#{$InconsolataPath}/#{$FontType}/Inconsolata-#{$FontType}.woff2") format("woff2"),
		url("#{$InconsolataPath}/#{$FontType}/Inconsolata-#{$FontType}.woff") format("woff"),
		url("#{$InconsolataPath}/#{$FontType}/Inconsolata-#{$FontType}.ttf") format("truetype"),
		url("#{$InconsolataPath}/#{$FontType}/Inconsolata-#{$FontType}.eot") format("eot");
}
