.print-template-izvjestaji {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    table {
      caption-side: bottom;
      width: 100%;
      border-collapse: collapse;
      td {
        border: 1px solid $color-content-lines;
        text-align: left;
        padding: 0.5rem 1rem;
        &:first-child {
          font-weight: 500;
          width: 50%;
        }
      }
      tr {
        cursor: default !important;
      }
      tr.block {
        display: block;
        width: 100%;
      }
      td.break {
        float: left;
        line-height: 22px;
        width: 200.75% !important;
        padding: 20px;
        border: none;
      }
      .td-w-100 {
        width: 100% !important;
      }
      caption {
        background-color: #a2c0d9;
        height: 10px;
      }
      .bg-light-blue {
        background-color: #a2c0d9;
        width: 100%;
      }
    }
  }
  .page-izvjestaji {
    width: 210mm;
    min-height: 297mm;
    padding: 20mm;
    margin: 10mm auto;
    border: 1pt #d3d3d3 solid;
    border-radius: 5pt;
    background: #fff;
    box-shadow: 0 0 5pt rgba(0, 0, 0, 0.1);
  }

  .page-izvjestaji p {
    white-space: pre-line;
  }

  .word-break {
    word-wrap: break-word;
  }

  // .table-print {
  //   padding: 1cm;
  //   border: 5px red solid;
  //   height: 257mm;
  //   outline: 2cm #ffeaea solid;
  // }
  // @page {
  //   size: A4;
  //   margin: 0 !important;
  // }
  @media print {
    html,
    body {
      // width: 210mm;
      // height: 297mm;
      // margin: 0;
      .page-izvjestaji {
        margin: 0 !important;
        border: initial !important;
        border-radius: initial !important;
        width: initial !important;
        min-height: initial;
        box-shadow: initial;
        background: #fff !important;
        page-break-after: always !important;

      table {
        display: table !important;
        table-layout: fixed !important;
        page-break-inside: auto !important;
        margin-left: auto !important;
        margin-right: auto !important;
        border-collapse: collapse !important;
        border: none !important;
        border-spacing: 0 !important;
        font-size: 11pt !important;
        width: 100%;
        // border-color: #000000;
        // border-right: 1px solid;
        tr {
          display: table-row;
          page-break-inside: avoid;
          page-break-after: auto;
          // border-color: rgb(184, 0, 0);
          border-style: none !important;
          border-width: 0 !important;
          width: 100%;
        }
        td {
          display: table-cell;
          // border-color: rgb(184, 0, 0);
          border: 1pt solid !important;
          text-align: left !important;
          //width: 50%;
        }
        td:first-child {
          font-weight: 600;
        }
        .bg-light-blue {
          background-color: #a2c0d9;
          width: 100%;
        }
        .block {
          display: block!important;
          width: 100% !important;
          border: 1pt solid !important;
          border-style: none !important;
          border-width: 1pt !important;
        }
        .break {
          float: left!important;
          line-height: 22pt;
          width: 201.5% !important;
          border-left: 1pt solid!important;
          border-right: 1pt solid!important;
          border-top: 0!important;
          border-bottom: 0!important;
        }
      }
    }
  }
    .page-izvjestaji {
      margin: 0;
      box-shadow: 0;
      border: initial;
      border-radius: initial;
      width: initial;
      min-height: initial;
      box-shadow: initial;
      background: initial;
      page-break-after: always;
    }
  }